import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { SERVICE_CARD_CONFIG } from '../../config';
export default defineComponent({
  name: 'TSeniorService',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const platformList = (SERVICE_CARD_CONFIG === null || SERVICE_CARD_CONFIG === void 0 ? void 0 : SERVICE_CARD_CONFIG[props.type].list) || [];
    return {
      router,
      platformList
    };
  }
});