import seniorIcon1 from '../images/senior_service_1.png';
import seniorIcon2 from '../images/senior_service_2.png';
import seniorIcon3 from '../images/senior_service_3.png';
import seniorIcon4 from '../images/senior_service_4.png';
import seniorIcon5 from '../images/senior_service_5.png';
import seniorIcon6 from '../images/senior_service_6.png';
import seniorIcon7 from '../images/senior_service_7.png';

export const SENIOR_CUSTOMIZE_SERVICE = [
    {
        title: '调研与策略制定',
        desc: '产品调研，目标用户调研，竞品调研，设计针对性投放方案',
        icon: seniorIcon1,
    },
    {
        title: '广告账户搭建',
        desc: '所有可投放平台的开户，根据物料和策略进行广告账户搭建',
        icon: seniorIcon2,
    },
    {
        title: '文案翻译',
        desc: '针对广告文案投放国家做本地化翻译',
        icon: seniorIcon3,
    },
    {
        title: '广告账户优化',
        desc: '实时监控表现，根据表现优化投放效果，包括关键词的优化、广告语优化、A/B测试、用户画像优化、结构优化、网站优化等',
        icon: seniorIcon4,
    },
    {
        title: '落地页搭建',
        desc: '根据海外采购者需求与浏览习惯设计的定制化流量承接与留咨页面',
        icon: seniorIcon5,
    },
    {
        title: '汇报分析',
        desc: '定期提供广告表现数据报告，灵活提供优化建议与反馈',
        icon: seniorIcon6,
    },
    {
        title: '素材设计与编辑',
        desc: '文字广告素材设计，图片素材编辑，视频素材编辑',
        icon: seniorIcon7,
    },
    // {
    //     title: '素材设计与编辑',
    //     desc: '文字广告素材设计，图片素材编辑，视频素材编辑',
    //     icon: ''
    // },
];