import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { SENIOR_CUSTOMIZE_SERVICE } from '../config';
import { sliceArr } from '@/base/utils';
export default defineComponent({
  name: 'TSolutionService',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const colSpan = 2;
    const customizeList = sliceArr(SENIOR_CUSTOMIZE_SERVICE || [], colSpan);
    return {
      router,
      customizeList
    };
  }
});